















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import PayListItem from './PayListItem.vue'
@Component({
  components: {
    PayListItem
  }
})
export default class extends Vue {
  @Prop()
  list!: ChargeListItem[]
  @Prop({
    type: String,
    default: ''
  })
  game_vip_name!: string
  itemIndex = 0
  created() {
    //this.change()
  }
  @Emit('click')
  websiteShow() {
    return
  }

  click(index: number) {
    this.itemIndex = index
    this.$emit('change', this.list[this.itemIndex] || {})
  }

  // @Watch('itemIndex', {
  //   immediate: true
  // })
  // async change() {
  //   console.log('this.list[this.itemIndex]', this.list[this.itemIndex])
  //   this.$emit('change', this.list[this.itemIndex] || {})
  // }
}
