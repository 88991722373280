
































import { Component, Vue } from 'vue-property-decorator'
import BuyCoin from './components/BuyCoin.vue'

import { CommonModule } from '@/store/modules/common'

import { getQueryVariable } from '@/common/utils'
import { callappFunc_onH5PaySuccess } from '@/common/utils/bridge'
import { getOrderNew } from '@/pages/index/api/payment'
import WalletSkeleton from './components/WalletSkeleton.vue'
import { LoyoutModule } from '@/store/modules/layout'
import { unitFormat } from '@/common/utils'
import LimitPop from './components/LimitPop.vue'
import Banner from './components/Banner.vue'
import { walletStorage } from '@/pages/videoGame/utils'
import { WalletModule } from '@/store/modules/wallet'
import CardBuy from './components/CardBuyNew.vue'
import DayGift from './components/DayGiftNew.vue'

@Component({
  components: {
    BuyCoin,
    WalletSkeleton,

    LimitPop,
    Banner,
    CardBuy,
    DayGift
  }
})
export default class extends Vue {
  activeName = 0
  showSke = true
  activeTab = 0
  unitFormat = unitFormat
  get coinNum() {
    return CommonModule.coin
  }
  get kCoinNum() {
    return CommonModule.k_coin
  }
  get kBeanNum() {
    return CommonModule.k_bean
  }
  timer: number | undefined

  async getOrder(orderno: string) {
    console.log('getOrder')
    const { data } = await getOrderNew(orderno)
    if (data.state == 1) {
      clearInterval(this.timer)
      sessionStorage.clear()
      this.handlSuccess(data.money)
    }
  }
  async checkOrder() {
    const lastOrderNo = walletStorage.getItem('lastOrderNo')
    const orderno = getQueryVariable('orderno') as string
    if (lastOrderNo == orderno) return
    walletStorage.setItem('lastOrderNo', orderno)
    this.getOrder(orderno)
    let count = 0
    this.timer = setInterval(async () => {
      if (count == 60) {
        clearInterval(this.timer)
        return
      }
      count += 2
      await this.getOrder(orderno)
    }, 2000)
  }

  async getChargeCoin() {
    ;(this.$refs.buyCoinRef as any).getChargeConfig(false)
  }
  handlSuccess(money: number) {
    callappFunc_onH5PaySuccess({ money })
    this.getChargeCoin()
    if (getQueryVariable('game_give_amount')) {
      WalletModule.setCardRevicePop(true)
    }
  }
  goDetail() {
    this.$router.push({
      name: 'walletDetail'
    })
  }
  created() {
    const title: any = this.$t('钱包')
    LoyoutModule.setTitle(title)
    if (getQueryVariable('orderno')) {
      this.checkOrder()
    }
  }
}
