





























import { Toast } from 'vant'
import { Component, Vue } from 'vue-property-decorator'
import WalletSkeleton from './WalletSkeleton.vue'
import { getChargeList, getIosConfig } from '@/pages/user/api'
import PayList from './PayList.vue'
import { WalletModule } from '@/store/modules/wallet'
import { isIOS, getQueryVariable } from '@/common/utils'
import {
  handlePayAliPay,
  handlePayWeChatH5,
  getOrderNew
} from '@/pages/index/api/payment'
import { CommonModule } from '@/store/modules/common'
import { unitFormat } from '@/common/utils'
import {
  callAppFunc_JumpIosPay,
  callAppFunc_openBrowser,
  callAppFunc_showHideBottomNavigationBar,
  callAppFunc_onCoinsChargeWechatPay,
  callH5Func_nativePayResult,
  callappFunc_onH5PaySuccess
} from '@/common/utils/bridge'
import PayMethod from './PayMethod.vue'
@Component({
  components: {
    WalletSkeleton,
    PayList,
    PayMethod
  }
})
export default class extends Vue {
  async created() {
    callAppFunc_showHideBottomNavigationBar({ status: 0 })
    console.log('uid', CommonModule.userId)
    this.getChargeConfig()
    if (isIOS) {
      const result = await getIosConfig()
      if (result.data.ios_pay_switch == 1) {
        this.showWebsite = true
        this.ios_pay_url = result.data.ios_pay_url
      }
    }
    // if (getQueryVariable('orderno')) {
    //   this.checkOrder()
    // }
  }
  unitFormat = unitFormat
  showWebsite = false //控制是否展示去官网充值
  ios_pay_url = ''
  payInfo: Partial<ChargeListItem> = {}
  loading = true
  payList: ChargeListItem[] = []
  coin = 0
  mounted() {
    // 注册支付成功后的回调
    callH5Func_nativePayResult((resp) => {
      console.log('callH5Func_nativePayResult', resp);
      this.getChargeConfig()
    })
  }
  get showAction() {
    return WalletModule.showMethod
  }
  get payType() {
    return WalletModule.payType
  }
  isIOS = isIOS
  game_vip_name = ''

  rechargLoading = false
  async getChargeConfig(flag = true) {
    console.log('isIOS', this.isIOS)
    const { data } = await getChargeList()
    this.$emit('load', flag)
    this.loading = false
    this.coin = data.coin
    this.payList = data.list
    //WalletModule.setPayType(data.charge)

    this.game_vip_name = data.game_vip_name
    CommonModule.setCoin(data.coin)
    CommonModule.setKBean(data.k_bean)
    CommonModule.setKCoin(data.k_coin)
  }
  get customInputValue() {
    return WalletModule.customInputValue
  }
  get getBtnText() {
    const item = this.payInfo
    let preText = this.$t('确认充值')
    if (!isIOS) {
      preText =
        this.payType === 1
          ? this.$t('使用微信支付充值')
          : this.$t('使用支付宝支付充值')
    }
    if (item) {
      if (Number(this.customInputValue) > 0) {
        return preText + this.customInputValue + '元'
      }
      const val = item.money
      console.log('money', item.money)
      if (val == '0.00' || Number(val) <= 0) {
        return ''
      }
      return preText + (val || '') + '元'
    }
    return ''
  }
  get h5() {
    return getQueryVariable('h5')
  }
  changePay(item: ChargeListItem) {
    this.payInfo = item
    WalletModule.setShowMethod(true)
  }
  changePayType(val: number) {
    WalletModule.setPayType(val)
  }

  async recharge() {
    if (this.rechargLoading) return
    this.rechargLoading = true
    const data: any = {
      ...this.payInfo,
      payType: this.payType,
      data: this.payInfo.name || `${this.payInfo.coin}钻石`,
      sourceType: 0, // 0-普通充值 1-超值礼包
      changeid: this.payInfo.id,
      charge_type: 1
    }
    if (data.type === '2') {
      data.money = data.timelimit_money
    }
    if (Number(data.money) <= 0) {
      WalletModule.setCustomInputStatus(true)
      return Toast('请输入金额')
    }
    // iOS是否走app内部进行支付
    const needJumpIosPay = false
    if (!this.isIOS) {
      // needJumpIosPay = true; // iOS审核时需要开启
    }
    console.log('data', data);
    if (!needJumpIosPay) {
      const obj = {
        uid: CommonModule.userId,
        charge_type: 1,
        changeid: data.id,
        coin: data.coin,
        money: data.money,
        give: data.give || 0
      }
      try {
        if (this.payType === 1) {
          // await this.payWeChatH5(obj)
          await this.payWeChatH5Native(obj)
        } else if (this.payType === 2) {
          await this.payAliPay(obj)
        }
      } finally {
        this.rechargLoading = false
        //Toast.clear()
      }
      //Toast.clear()
    } else {
      const res = await callAppFunc_JumpIosPay(data)
      if (res && res == 'needRefreshTask') {
        this.getChargeConfig()
      }
    }
  }

  async payWeChatH5(obj: any) {
    //微信
    const front = isIOS ? 2 : 3
    try {
      const weiInfo = await handlePayWeChatH5(obj, front)
      const {
        data: { mweb_url = '' }
      } = weiInfo
      if (weiInfo.data.code == -1) {
        WalletModule.setShowMethod(false)
        WalletModule.setChargeLimit(weiInfo.data.msg)
        WalletModule.setShowLimitPop(true)

        return false
      }
      sessionStorage.setItem('onec', '1')
      setTimeout(() => {
        window.location.href = mweb_url
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }
  // 呼起客户端进行微信支付
  async payWeChatH5Native(obj: any) {
    const coins = (obj.coin || 0) + (obj.give || 0)
    const data = {
      itemId: obj.changeid,// 当前购买项的id
      coins: coins,// 可获得的金币总数（需要加上赠送部分的金币数）
    }
    //微信
    try {
      callAppFunc_onCoinsChargeWechatPay(data)
    } catch (error) {
      console.error(error)
    }
  }
  async payAliPay(obj: any) {
    //支付宝
    const front = isIOS ? 2 : 3
    try {
      const aliPay = await handlePayAliPay(obj, front)
      const {
        data: { html = '' }
      } = aliPay
      if (aliPay.data.code == -1) {
        WalletModule.setShowMethod(false)
        WalletModule.setChargeLimit(aliPay.data.msg)
        WalletModule.setShowLimitPop(true)

        return false
      }
      sessionStorage.setItem('onec', '1')
      const _a = document.createElement('div')
      _a.innerHTML = html
      document.body.append(_a)
      setTimeout(() => {
        document.forms[0].submit()
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }

  goRecord() {
    //
    this.$router.push('/wallet/detail')
  }
  goHelp() {
    this.$router.push('/help-feedback')
  }
  openBrowser() {
    const URL = this.ios_pay_url
    callAppFunc_openBrowser({ url: URL })
  }
  timer: number | undefined
  async checkOrder() {
    const orderno = getQueryVariable('orderno') as string
    this.getOrder(orderno)
    let count = 0
    this.timer = setInterval(async () => {
      if (count == 60) {
        clearInterval(this.timer)
        return
      }
      count += 2
      await this.getOrder(orderno)
    }, 2000)
  }
  async getOrder(orderno: string) {
    console.log('getOrder')
    const { data } = await getOrderNew(orderno)

    if (data.state == 1) {
      clearInterval(this.timer)

      sessionStorage.clear()
      this.handlSuccess(data.money)
    }
  }
  handlSuccess(money: number) {
    callappFunc_onH5PaySuccess({ money })
    this.getChargeConfig()
  }
  closeAction() {
    WalletModule.setShowMethod(false)
  }
  toAgreement() {
    window.location.href = '/h5/main/user.html#/richH5?id=36'
  }
}
