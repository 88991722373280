/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-03-31 11:26:37
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-03-31 11:27:24
 */
import { TakStorage } from '@/common/utils/storage'

export const walletStorage = new TakStorage({ prefix: 'wallet' })
