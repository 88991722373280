























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

import { WalletModule } from '@/store/modules/wallet'
import { isIOS, getAppLanguage } from '@/common/utils'
import { unitFormat } from '@/common/utils'
@Component
export default class extends Vue {
  @Prop()
  item!: ChargeListItem
  @Prop()
  active!: boolean
  @Prop({
    type: String,
    default: '元'
  })
  orgUnit!: string
  @Prop({
    type: String,
    default: '金币'
  })
  targetUnit!: string
  @Prop({
    type: String,
    default: ''
  })
  game_vip_name!: string
  language = getAppLanguage()
  unitFormat = unitFormat
  handleClick() {
    WalletModule.setCustomInputStatus(true)
  }
  isIOS = isIOS

  get showGive() {
    if (this.item.type && this.item.is_first_charge) {
      return this.item.give
    } else if (this.item.type == 0 && this.item.give > 0) {
      return this.item.give
    } else {
      return 0
    }
  }
  get showSendLabel() {
    if (this.item.type && this.item.is_first_charge) {
      return true
    } else if (this.item.type == 0 && this.item.label) {
      return true
    }
    return false
  }

  get vipSendText() {
    if (this.game_vip_name && this.item.game_give_amount) {
      if (this.language == 'uyghur') {
        return `${this.game_vip_name} ga ${this.item.game_give_amount} koxudu`
      } else {
        return `${this.game_vip_name}额外赠送${this.item.game_give_amount}金币`
      }
    } else {
      return ''
    }
  }
}
